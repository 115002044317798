import React, { useContext } from 'react';
import { Select, MenuItem, Box, Typography } from '@material-ui/core';
import { DashboardContext } from "./v4Context.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    alignContent: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    overflow: 'auto',
    width: 'auto',
    // height: '100%',
  },
  select: {
    padding: theme.spacing(1),
    height: '45px',
    width: 'auto',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  leagueName: {
    fontWeight: 500,
  },
}));


const LeagueSelect = () => {
  const classes = useStyles();
  const { leagues, activeLeague, setActiveLeague } = useContext(DashboardContext);

  const handleChange = (event) => {
    setActiveLeague(event.target.value);
  };

  if (!leagues || leagues.length === 0) {
    return null; // No leagues to display
  }

  return (
    <Box elevation={0} className={classes.componentContainer}>
      <Select
        className={classes.select}
        value={activeLeague || ''}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => (
          <Typography variant="body1" className={classes.leagueName}>
            {selected || 'Select a League'}
          </Typography>
        )}
      >
        <MenuItem value="" disabled>
          Select a league
        </MenuItem>
        {leagues.map((league, index) => (
          <MenuItem key={index} value={league} className={classes.menuItem}>
            <Typography variant="body1" className={classes.leagueName}>
              {league}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};


export default React.memo(LeagueSelect);
