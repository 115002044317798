import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Box, Tooltip, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: '15px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    width: 35,
    height: 35,
  },
}));

const IndexReference = (index) => {
  const references = [
    'Season Total',
    'Season by Away / Home',
    'Season by Underdog / Favorite',
    'Season Conf. / Non-Conf.',
    'Last 3 Games Total',
    'Last 3 Games by Away / Home',
    'Last 3 Games by Underdog / Favorite',
    'Last 3 Games Conf. / Non-Conf.',
  ];
  return references[index] || '';
};

const DownloadButton = ({ downloadFunction, icon, title, description }) => {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      title={
        <React.Fragment>
          <Typography color="inherit" variant="h6">
            {title}
          </Typography>
          <Typography color="inherit" variant="body2">
            {description}
          </Typography>
        </React.Fragment>
      }
      placement="top"
    >
      <div className={classes.iconContainer} onClick={downloadFunction}>
        <img src={icon} alt={`${title} Icon`} className={classes.icon} />
      </div>
    </Tooltip>
  );
};

const DataDownloads = ({
  plan,
  data,
  visitor,
  vSpread,
  vMoneyline,
  home,
  hSpread,
  hMoneyline,
  overUnder,
  eventTime,
  preferredBookmaker,
}) => {
  const freeRows = 5;

  const headers = [
    'Data Field',
    'Data Category',
    'Split',
    `${visitor} (Away) Value`,
    `${home} (Home) Value`,
    `${visitor} (Away) Games`,
    `${home} (Home) Games`,
    'League Max',
    'League Min',
    'League Average',
    'Standard Deviation',
    'Variance',
  ];

  const additionalRows = [
    ['Current Time', new Date().toLocaleDateString(), '', new Date().toLocaleTimeString()],
    ['Game Time', new Date(eventTime).toLocaleDateString(), '', new Date(eventTime).toLocaleTimeString()],
    ['VISITOR', 'at', 'HOME'],
    [visitor, 'at', home],
    [],
    ['Preferred Bookmaker', preferredBookmaker, '', ''],
    [],
    headers,
  ];

  const reshapeData = () =>
    data.map((row) => {
      const rowName = row.ref.at(-1);
      const categoryTree = row.ref.slice(0, -1).join('-');
      const indexRef = IndexReference(row.index);
      return [
        rowName,
        categoryTree,
        indexRef,
        row.VisitorValue,
        row.HomeValue,
        row.VisitorGames,
        row.HomeGames,
        row.CurrentContext.Max,
        row.CurrentContext.Min,
        row.CurrentContext.Mean.toFixed(1),
        row.CurrentContext.Standard_Deviation.toFixed(2),
        row.CurrentContext.Variance.toFixed(1),
      ];
    });

  const downloadXLSX = () => {
    const reshapedData = [...additionalRows, ...reshapeData()];
    const worksheet = XLSX.utils.json_to_sheet(reshapedData);
    const workbook = XLSX.utils.book_new();
    const timestamp = `${new Date(eventTime).toLocaleDateString().replace(/\//g, '-')}_${new Date(
      eventTime
    ).toLocaleTimeString().replace(/:/g, '-')}`;
    XLSX.utils.book_append_sheet(workbook, worksheet, `${visitor.slice(0, 4)}-${home.slice(0, 4)}`);
    XLSX.writeFile(workbook, `${timestamp}_${visitor}_at_${home}.xlsx`);
  };

  const downloadCSV = () => {
    const reshapedData = [...additionalRows, ...reshapeData()];
    const csvContent = reshapedData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const timestamp = `${new Date(eventTime).toLocaleDateString().replace(/\//g, '-')}_${new Date(
      eventTime
    ).toLocaleTimeString().replace(/:/g, '-')}`;
    saveAs(blob, `${timestamp}_${visitor}_at_${home}.csv`);
  };

  const xlsIcon = 'https://upload.wikimedia.org/wikipedia/commons/f/f3/.xlsx_icon.svg';
  const csvIcon = 'https://upload.wikimedia.org/wikipedia/commons/c/c6/.csv_icon.svg';

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
      <Box mr={2}>
        <DownloadButton
          downloadFunction={downloadCSV}
          icon={csvIcon}
          title="Download CSV File"
          description="Download this collection of stats into a comma-separated text file (.csv)."
        />
      </Box>
      <DownloadButton
        downloadFunction={downloadXLSX}
        icon={xlsIcon}
        title="Download XLSX File"
        description="Download this collection of stats into a Microsoft Excel spreadsheet (.xlsx)."
      />
    </Box>
  );
};

export default DataDownloads;
