import React from "react";
import { Typography, Button, Card, CardContent, makeStyles } from "@material-ui/core";
import { Link } from "../util/router";
import { useAuth } from "../util/auth.js";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 700,
    fontSize: "1.8rem",
  },
  highlight: {
    color: "#ff9900", // Highlight color
  },
  card: {
    maxWidth: 800,
    margin: "0 auto",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: "15px",
    boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
    textAlign: "left",
  },
  videoEmbed: {
    marginTop: theme.spacing(2),
    borderRadius: "15px",
    overflow: "hidden",
    aspectRatio: "16 / 9",
  },
  description: {
    marginTop: theme.spacing(2),
    fontSize: "0.9rem",
    color: "#333",
  },
  button: {
    backgroundColor: "#f15a29",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d44c20",
    },
  },
}));

const StatsComponent = () => {
  const classes = useStyles();
    const auth = useAuth(); 
  return (
    <div className={classes.container}>
      {/* Title */}
      <Typography className={classes.title}>
        Choose <span className={classes.highlight}>YOUR Stats</span> From An <span className={classes.highlight}>Exhaustive List</span>
      </Typography>

      <Card className={classes.card}>
        <CardContent>
          <div className={classes.videoEmbed}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/gvXR76FyqEU?si=btAh-53ZSlLM3E8X"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <Button
            component={Link}
            to={
              auth.user
              ? `/purchase/$standard`
              : `/auth/signup?next=/purchase/standard`
            }
            size="large"
            fullWidth={false}
            variant="contained"
            className={classes.button}>
            Try Statfactory.io
            </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default StatsComponent;
