import React, { useContext, useEffect, useState } from 'react';
import { DashboardContext } from './v4Context.js';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Select, MenuItem } from '@material-ui/core';
import { format, isToday, addDays, isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    alignContent: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    overflow: 'auto',
    width: 'auto',
    height: '100%',
  },
  select: {
    padding: theme.spacing(1),
    height: '45px',
    width: 'auto',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  teamName: {
    fontWeight: 500,
  },
  time: {
    fontStyle: 'italic',
    color: theme.palette.text.secondary,
  },
}));

  const GameDropdown = () => {
    const classes = useStyles();
    const { fetchFromAPI, activeLeague, game, setGame, setGameData } = useContext(DashboardContext);
    const [gameList, setGameList] = useState(null);
    useEffect(() => {
      if (activeLeague) {
        setGame(null); // Clear selection when league changes
        setGameData(null);
        setGameList(null);
        fetchFromAPI('v3/games', 'POST', { league: activeLeague }).then((response) => {
          // Sort games by date in ascending order
          const sortedGames = response.sort((a, b) => new Date(a.eventTime) - new Date(b.eventTime));
          setGameList(sortedGames);
        });
      }
    }, [activeLeague]);
  
    const formatEventTime = (eventTime) => {
      const eventDate = new Date(eventTime);
      const timeZone = 'America/New_York';
      const zonedDate = toZonedTime(eventDate, timeZone);
      const today = new Date();
      const tomorrow = addDays(today, 1);
      if (isToday(zonedDate)) {
        return `Today, ${format(zonedDate, 'hh:mm a')} EST`;
      } else if (isSameDay(zonedDate, tomorrow)) {
        return `Tomorrow, ${format(zonedDate, 'hh:mm a')} EST`;
      } else {
        return `${format(zonedDate, 'MMMM d, h:mm')} pm`;
      }
    };
  
    const handleGameChange = (event) => {
      const selectedGame = gameList.find((g) => g.oddsAPIId === event.target.value);
      if (selectedGame) {
        setGame(selectedGame); // Update the selected game in the context
      }
    };
  
    if (!gameList) {
      return null; // Return nothing while loading or if no games
    }
    return (
      <Box className={classes.componentContainer}>
        <Select
          className={classes.select}
          value={game?.oddsAPIId || ''} // Use game.id or an empty string as the value
          onChange={handleGameChange}
          displayEmpty
          renderValue={(selectedId) => {
            const selectedGame = gameList.find((g) => g.oddsAPIId === selectedId)
           
            return selectedGame ? (
              <div>
                <Typography variant="body1" className={classes.teamName}>
                  {selectedGame.visitor.teamName} at {selectedGame.home.teamName}
                </Typography>
                <Typography variant="body2" className={classes.time}>
                  {formatEventTime(selectedGame.eventTime)}
                </Typography>
              </div>
            ) : (
              'Select a Game'
            );
          }}
        >
          <MenuItem value="" disabled>
            Select a Game
          </MenuItem>
          {gameList.map((g) => { 
  const homeIcon = g.home.logoURL; // Home team logo
  const visitorIcon = g.visitor.logoURL; // Visitor team logo
  return (
    <MenuItem key={g.oddsAPIId} value={g.oddsAPIId} className={classes.menuItem}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {/* Visitor Team Icon */}
        <img
          src={visitorIcon}
          alt={`${g.visitor.teamName} logo`}
          style={{ width: 32, height: 32, borderRadius: '50%' }}
        />
        {/* Visitor Team Name */}
        <Typography variant="body1" className={classes.teamName}>
          {g.visitor.teamName}
        </Typography>

        {/* VS Separator */}
        <Typography variant="body1" style={{ margin: '0 8px' }}>
          at
        </Typography>

        {/* Home Team Name */}
        <Typography variant="body1" className={classes.teamName}>
          {g.home.teamName}
        </Typography>

        {/* Home Team Icon */}
        <img
          src={homeIcon}
          alt={`${g.home.teamName} logo`}
          style={{ width: 32, height: 32, borderRadius: '50%' }}
        />
      </div>
      <Typography variant="body2" className={classes.time} style={{ marginTop: 4 }}>
        {formatEventTime(g.eventTime)}
      </Typography>
    </MenuItem>
  );
})}

        </Select>
      </Box>
    );
  };
  
export default React.memo(GameDropdown);
