import React, { useContext } from 'react';
import { DashboardContext } from './v4Context.js';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import GameDropdown from './GameDropdown.jsx';
import StatFactoryPatchNotes from './StatFactoryPatchNotes.jsx';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    // margin: theme.spacing(2),
    width: '90%',
    height: '80vh', // Full viewport height
    textAlign: 'center',
  },
  instructionText: {
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },
  leagueSelectWrapper: {
    width: '80%',
    maxWidth: 500,
    height: '100%',
    padding: theme.spacing(2),
  },
}));

const FirstTimeGameSelect = () => {
  const classes = useStyles();
  const { fetchFromAPI, activeLeague, game, setGame, setGameData } = useContext(DashboardContext);

  return (
    <Paper elevation={2} className={classes.componentContainer}>
      <Typography variant="h4" className={classes.instructionText}>
        Step 2: Select a game
      </Typography>
        <Typography variant="body1">
            Great, you selected a league. You can now select a game to view more details.
         Again, You can change this selection at any time using the input at the top of the screen.
        </Typography>

          <GameDropdown />
          <StatFactoryPatchNotes />
    </Paper>
  );
};

export default FirstTimeGameSelect;
