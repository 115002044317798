import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import monitor from './Monitor.png'; // Import the image
import degenLogo from './degenLogo.jpg'; // Import the image
import ListenToDegenerates from "./ListenToDegenerates";
import { Link } from "../util/router";
import { useAuth } from "../util/auth.js";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
    maxWidth: "1200px",
    margin: "0 auto",
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  videoContainer: {
    position: "relative",
    paddingBottom: "56.3%",
    height: 0,
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: "none",
    borderTopLeftRadius: "15px", 
    borderTopRightRadius: "15px",
    boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
  },
  belowImage: {
    width: "100%",
    height: "auto",
    display: "block",
  },
  leftContent: {
  },
  degenContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    textAlign: "center",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      // flexDirection: "column",
    },
  },
  degenImage: {
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
    borderRadius: theme.shape.borderRadius,
  },
  list: {
    listStyle: "disc",
    paddingLeft: theme.spacing(5), // Add some spacing from the edge
  },
  buttonWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  button: {
    backgroundColor: "#f15a29",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d44c20",
    },
  },
}));

const LandingPanel1v4 = () => {
  const classes = useStyles();
  const auth = useAuth();


  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {/* Header Section */}
        <Grid item xs={12} className={classes.header}>
        </Grid>

        {/* Video and Content Section */}
        <Grid container item xs={12} spacing={4} alignItems="center">
          {/* Left Section: Key Features */}
          <Grid item xs={12} sm={5} className={classes.leftContent}>
          <Typography variant="h4">Your Custom <span style={{ color: "#FFA500" }}>Sports Betting </span>Dashboard</Typography>
            <Typography variant="h6">Key Features:</Typography>
            <ul className={classes.list}>
              <li>Select a league</li>
              <li>Pick a game</li>
              <li>Choose stats to view</li>
              <li>Instant reports</li>
              <li>Season stats and trends
              <ul className={classes.list}>
              <li>Season Totals, Recent Games</li>
              <li>As Home/Away, Fave/Dog, Conference Splits</li>
              <li>Total, Average Per Game, Normed and Z-Score Views</li>
              <li>Odds History for US Bookmakers</li>
              <li>Charts, Injuries, Trades and News.</li>
            </ul>
              </li>
            </ul>
          </Grid>

          {/* Right Section: Video and Image */}
          <Grid item xs={12} sm={7}>
            <div className={classes.videoContainer}>
            <iframe
              className={classes.iframe}
              src="https://www.youtube.com/embed/jrHmLQ3muzo?si=69TkRYm9yg59DW0S&mute=1"
              title="Sports Betting Dashboard Demo"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            </div>
            <img
              src={monitor} // Use the imported image
              alt="Statfactory Dashboard"
              className={classes.belowImage}
            />
          </Grid>
        </Grid>

        {/* CTA Section */}
        <Grid container className={classes.buttonWrapper}>
          <Grid item xs={12}>
            <Button 
            component={Link}
            to={
              auth.user
              ? `/purchase/$standard`
              : `/auth/signup?next=/purchase/standard`
            }
            size="large"
            fullWidth={false}
            variant="contained" className={classes.button}>
              Try StatFactory.io
            </Button>
          </Grid>
        </Grid>
        {/* Degenerates Section */}
        <Grid container className={classes.degenContainer} spacing={2} alignItems="center" justifyContent="center">
  {/* Left: Image */}
  <Grid item xs={12} sm={4}>
    <img src={degenLogo} alt="Degenerates" className={classes.degenImage} />
  </Grid>

  {/* Right: Text */}
  <Grid item xs={12} sm={8}>
  <Typography variant="h4">Welcome <span style={{ color: "#FFA500" }}>DEGENERATES</span>!</Typography>
    <Typography variant="h5">
      To claim the Kendall and Hammer StatFactory.io discount, use the code: <strong><span style={{ color: "#FFA500" }}>DEGENERATES</span></strong>
    </Typography>
    <ListenToDegenerates />
  </Grid>
</Grid>

      </Grid>
    </div>
  );
};

export default LandingPanel1v4;
