import React, { useEffect } from 'react';
import { useLocation } from "../util/router.js";
import { Helmet } from 'react-helmet';
import { useAuth } from '../util/auth.js';

const HelpCrunchGoogleTag = () => {
    const location = useLocation();

    useEffect(() => {
        // Push a pageview event to the GTM dataLayer on route change
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: location.pathname,
        });
    }, [location.pathname]);

    return (
        <Helmet>
            {/* Google Tag Manager (GTM) */}
            <script async src="https://www.googletagmanager.com/gtm.js?id=GTM-MLBF3674"></script>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js'
                    });
                `}
            </script>
        </Helmet>
    );
};


export default HelpCrunchGoogleTag;
