import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800,
    margin: 'auto',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  section: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  listItem: {
    padding: theme.spacing(0), // Reduce padding between items
  },
  listItemText: {
    fontSize: '0.8rem', // Smaller font size
  },
}));

const PatchNotes = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={3}>
      {/* Header */}
      <div className={classes.header}>
        <Typography variant="h4" gutterBottom>
          Patch Notes
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Version 4.0.3 - Released on December 10, 2024
        </Typography>
      </div>
      <Divider />

      {/* Known Bugs & Bug Fixes */}
      <div className={classes.section}>
        <Typography variant="h5" gutterBottom>
          🐞 Known Bugs & Bug Fixes
        </Typography>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Stat Charts: Charting Last n games does not display the correct information"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Matchup Report: Some complex composite stats display a 0 or null value"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Odds History Chart: Lines occasionally paint white, and are thus invisible."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Collection Manager: Custom Lists are currently device specific."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Matchup Report: Fixed some percentage and ratio calculations not displaying properly."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </div>
      <Divider />

      {/* Improvements */}
      <div className={classes.section}>
        <Typography variant="h5" gutterBottom>
          ⚙️ Improvements
        </Typography>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Matchup Report: Changed unavailable stat views to grey for better viewing."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Matchup Report: Added a red highlight and informative tooltip to those stats for which a lower value is better than a higher value."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Added Live Customer Service on the website for immediate assistance."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </div>
      <Divider />
     {/* Roadmap */}
     <div className={classes.section}>
        <Typography variant="h5" gutterBottom>
        🗺️ Roadmap
        </Typography>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Custom Composite Stats: Allow users to create their own composite stats."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Integrated Bet Calculator: Calculate potential payouts based on odds and bet amount."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Bet Tracking: Track your bets and see your win/loss ratio over time."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="AI-Powered Insights: Get personalized insights based on your betting history and preferences."
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </div>
      <Divider />
    </Paper>
  );
};

export default PatchNotes;
