import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { DashboardContext } from './v4Context.js';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    margin: theme.spacing(0),
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  table: {
      width: '100%',
    },
    tableContainer: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    width: '100%',
  },
  logo: {
    maxWidth: 50,
    maxHeight: 50,
  },
  weatherIcon: {
    maxWidth: 45,
    maxHeight: 45,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Adds a subtle drop shadow
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.info.light, 
  },
  headerRow: {
    // backgroundColor: theme.palette.grey[200],
  },
}));

const HorizGameSummary = () => {
  const classes = useStyles();
  const { game } = useContext(DashboardContext);
  
  const headers = [
    'Matchup',
    'Time & Place',
    'Host',
    'Division',
    'Status',
    'Season Games',
    'League Rank',
    'Division Games',
    'Game Day',
    'Weather Forecast'
  ];


  if (!game) return null
  const kelvinToFahrenheit = (kelvin) => {
    return parseFloat((((kelvin - 273.15) * 9/5) + 32).toFixed(1))
  };
  const capitalizeDescription = (description) => {
    return description
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  };

  const visitorData = [
    <img src={game.visitor.logoURL} className={classes.logo} alt="visitor logo" />,
    `${formatDate(game.eventTime)}`,
    'Visitor',
    `${game.visitor.conference} ${game.visitor.division}`,
    game.visitor.status,
    game.visitor.seasonRecord,
    game.visitor.leagueRank,
    game.visitor.divisionRecord,
    capitalizeDescription(game.weather.weather[0].description),
    <img src={`https://openweathermap.org/img/wn/${game.weather.weather[0].icon}@2x.png`} className={classes.weatherIcon} alt="visitor logo" />,
  ]
  const homeData = [
    <img src={game.home.logoURL} className={classes.logo} alt="home logo" />,
    <span>{game.venue.name}<br></br>{game.venue.location}</span>,
    'Home',
    `${game.home.conference} ${game.home.division}`,
    game.home.status,
    game.home.seasonRecord,
    game.home.leagueRank,
    game.home.divisionRecord,
    `${kelvinToFahrenheit(game.weather.temp)}° F`,
    `Wind: ${game.weather.wind_speed.toFixed(0)} mph from ${game.weather.wind_deg}°`
  ];


  return (
    <Box elevation={10} className={classes.componentContainer}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="game summary table">
          <TableHead>
            <TableRow className={classes.headerRow}>
              {headers.map((header, index) => (
                <TableCell key={index} align="center">
                  <Typography variant="body1">{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
           
            <TableRow>
              {visitorData.map((data, index) => (
                <TableCell key={index} align="center">
                  <Typography variant="body1">{data}</Typography>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {homeData.map((data, index) => (
                  <TableCell key={index} align="center">
                  <Typography variant="body1">{data}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HorizGameSummary;
