import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  logoRow: {
    marginTop: theme.spacing(2),
    alignItems: "center",
    
  },
  logo: {
    width: "50px", // Adjust the size of the logos as needed
    margin: theme.spacing(1),
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

const ListenToDegenerates = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} direction="column" alignItems="center">
      {/* Text */}
      <Grid item>
        <Typography variant="h6">Listen to the Degenerates Next Door on:</Typography>
      </Grid>

      {/* Logos Row */}
      <Grid item container className={classes.logoRow} spacing={2}>
        <Grid item>
          <a href="https://podcasts.apple.com/us/podcast/the-degenerates-next-door/id1762631806" target="_blank" rel="noopener noreferrer">
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/e/e7/Podcasts_%28iOS%29.svg'
              alt="Apple iTunes"
              className={classes.logo}
            />
          </a>
        </Grid>
        <Grid item>
          <a href="https://open.spotify.com/show/78pYt9eGs9JueHRmX4wRBt" target="_blank" rel="noopener noreferrer">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg"
              alt="Spotify"
              className={classes.logo}
            />
          </a>
        </Grid>
        <Grid item>
          <a href="https://www.youtube.com/playlist?list=PLuhn-YOSswmg25NKTSd-5yCHiJRadMGD-" target="_blank" rel="noopener noreferrer">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png"
              alt="YouTube"
              className={classes.logo}
            />
          </a>
        </Grid>
        <Grid item>
          <a href="https://x.com/DegenNxtDoorPod" target="_blank" rel="noopener noreferrer">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/9/95/Twitter_new_X_logo.png"
              alt="Twitter"
              className={classes.logo}
            />
          </a>
        </Grid>
        <Grid item>
          <a href="https://omny.fm/shows/the-degenerates-next-door/playlists" target="_blank" rel="noopener noreferrer">
            <img
              src="https://assets.omny.fm/assets/marketing/img/press/omnystudio-icon-dark.png"
              alt="Omny.fm"
              className={classes.logo}
            />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListenToDegenerates;
