import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useAuth } from "../util/auth.js";
import { Link } from "../util/router";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh", // Full viewport height for demonstration
  },
  button: {
    textTransform: "none", // Keeps text casing as typed
    backgroundColor: "#3f51b5",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2c387e",
    },
  },
}));

const ReturningCustomer = () => {
  const classes = useStyles();
  const auth = useAuth()
    console.log(auth.user)

  if (auth.user) {
      return (
        <div className={classes.container}>
          <Button component={Link} to="/dashboard" className={classes.button} variant="contained" size="large">
            Go To My Dashboard
          </Button>
        </div>
      );
  } else return null

};

export default ReturningCustomer;
