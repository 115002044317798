import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from "../util/auth.js"

export const DashboardContext = createContext()

//TODO: Change this to preset lists
let blankLists = [
  {
    sport: 'Baseball',
    contents: [
      {
        listname: 'My First Baseball Collection',
        sport: 'Baseball',
        stats: [
        ],
        description: 'A Simple Collection of Baseball Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
      },
      {
        listname: 'My Second Baseball Collection',
        sport: 'Baseball',
        stats: [
        ],
        description: 'A Simple Collection of Baseball Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
      },
    ],  
  },
  {
    sport: 'Basketball',
    contents: [
      {
        listname: 'My First Basketball Collection',
        sport: 'Basketball',
        stats: [
        ],
        description: 'A Simple Collection of Basketball Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
      },
      {
        listname: 'My Second Basketball Collection',
        sport: 'Basketball',
        stats: [
        ],
        description: 'A Simple Collection of Basketball Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
      },
    ],  
  },
  {
    sport: 'Football',
    contents: [
      {
        listname: 'My First Football Collection',
        sport: 'Football',
        stats: [
        ],
        description: 'A Simple Collection of Football Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
      },
      {
        listname: 'My Second Football Collection',
        sport: 'Football',
        stats: [
        ],
        description: 'A Simple Collection of Football Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
      },
    ],  
  },

]

// Custom hook to facilitate accessing context data
export const useDashboard = () => useContext(DashboardContext);

//DATA API URL
const apiUrl = process.env.REACT_APP_API_URL;
//Number of data rows for a non-subscriber?
const freeRows = 4

export const DataProvider = ({ children }) => {
  const [leagues, setLeagues] = useState([])
  const [activeLeague, setActiveLeague] = useState(null)
  const [sport, setSport] = useState(null)
  const [dictionary, setDictionary] = useState([])

  const [game, setGame] = useState(null)
  const [gameData, setGameData] = useState(null)
  const [myLists, setMyLists] = useState( localStorage.getItem('myLists') ? JSON.parse(localStorage.getItem('myLists')) : blankLists )
  const [list, setList] = useState(0);
  const [chartDataList, setChartDataList] = useState([])
  const [availableBookmakers, setAvailableBookmakers] = useState([])
  const [selectedBookmakers, setSelectedBookmakers] = useState([])
  const [betType, setBetType] = useState(localStorage.getItem('betType') || 'Moneyline')
  const [isDictionaryOpen, setDictionaryOpen] = useState(false)
  const [view, setView] = useState(0)



  const [selectedStat, setSelectedStat] = useState(null)
  const [selectedStatData, setSelectedStatData] = useState(null)
  const [odds, setOdds] = useState([])
  const [gameProps, setGameProps] = useState(null)

  const [expandedNodes, setexpandedNodes] = useState(['root'])
  
  const auth = useAuth()
  

  function addStat(ref, index) {
    let i = myLists.findIndex((s) => s.sport === sport )
    const statExists = myLists[i].contents[list].stats.some(([statRef, statIndex])  => {
      return JSON.stringify(statRef) === JSON.stringify(ref) && statIndex === index;
    });
    if (statExists) {
      setMyLists((prev) => {
        const newLists = [...prev];
        newLists[i].contents[list].stats = newLists[i].contents[list].stats.filter(([statRef, statIndex]) => {
          return JSON.stringify(statRef) !== JSON.stringify(ref) || statIndex !== index;
        });
        localStorage.setItem('myLists', JSON.stringify(newLists))
        return newLists;
      });
      return;
    } else {


      setMyLists((prev) => {
        const newLists = [...prev];
        newLists[i].contents[list].stats = [...newLists[i].contents[list].stats, [ ref, index ]];
        localStorage.setItem('myLists', JSON.stringify(newLists))
        return newLists;
      });
    }
  }

  function getDictionaryEntry (ref) {
    let dictionaryEntry = dictionary.find((entry) => entry.name === ref[0])
    dictionaryEntry = dictionaryEntry.contents.find((entry) => entry.statName === ref[ref.length - 1])
    return dictionaryEntry
  }

  const getBookmakerLink = (bookmakerId) => {
    let displayName = '';
    let url = '';

    console.log(bookmakerId)
    switch (bookmakerId) {
      case 'betmgm':
        displayName = 'BetMGM';
        url = 'https://www.betmgm.com';
        break;
      case 'betrivers':
        displayName = 'BetRivers';
        url = 'https://www.betrivers.com';
        break;
      case 'betus':
        displayName = 'BetUs';
        url = 'https://www.betus.com';
        break;
      case 'bovada':
        displayName = 'Bovada';
        url = 'https://www.bovada.lv';
        break;
      case 'fanduel':
        displayName = 'FanDuel';
        url = 'https://www.fanduel.com';
        break;
      case 'mybookieag':
        displayName = 'MyBookie';
        url = 'https://www.mybookie.ag';
        break;
      case 'williamhill_us':
        displayName = "Caesar's";
        url = 'https://www.williamhill.com/us';
        break;
      case 'draftkings':
        displayName = 'DraftKings';
        url = 'https://www.draftkings.com';
        break;
      case 'lowvig':
        displayName = 'LowVig';
        url = 'https://www.lowvig.ag';
        break;
      case 'betonlineag':
        displayName = 'BetOnline';
        url = 'https://www.betonline.ag';
        break;
      case 'betparx':
        displayName = 'betPARX';
        url = 'https://www.betparx.com/';
        break;
      case 'windcreek':
        displayName = 'Wind Creek';
        url = 'https://windcreekcasino.com/';
        break;
      case 'fliff':
        displayName = 'Fliff';
        url = 'https://www.getfliff.com/';
        break;
      case 'hardrockbet':
        displayName = 'Hard Rock Bet'
        url = 'https://www.hardrock.bet/';
        break;
      case 'espnbet':
        displayName = 'ESPN Bet'
        url = 'https://espnbet.com/';
        break;
      case 'ballybet':
        displayName = 'Bally Bet'
        url = 'https://play.ballybet.com/';
        break;
      default:
        displayName = 'Unknown Bookmaker';
        url = '#';
    }

    return (
      [displayName, url]
    );
  };
  const fetchFromAPI = useCallback(async (endpoint, method = 'GET', data = {}) => {
    data.user = auth.user ? auth.user.id : null;
    //TODO: Append all user saved data from localStorage, including lists, etc.
    try {
      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: method !== 'GET' ? JSON.stringify(data) : undefined,
      };
      const response = await fetch(`${apiUrl}/${endpoint}`, options);
      if (!response.ok) throw new Error('Network response was not ok')
      if (endpoint === 'userCollections') return response
      else return response.json();
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  })

  //Fetch the leagues from the API and set the state.
  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const _leagues = await fetchFromAPI('v3/list');
        setLeagues(_leagues);
          if (localStorage.getItem('activeLeague')) setActiveLeague(localStorage.getItem('activeLeague'))
          if (localStorage.getItem('myLists')) setMyLists(JSON.parse(localStorage.getItem('myLists')))
          if (localStorage.getItem('betType')) setBetType(localStorage.getItem('betType'))
          if (localStorage.getItem('selectedBookmakers')) setSelectedBookmakers(JSON.parse(localStorage.getItem('selectedBookmakers')))


        } catch (error) {
        console.error('Error fetching leagues:', error);
      }
    };
    fetchLeagues();
  }, [])
  
  //If activeLeague changes, update the dictionary and get the games list.
  useEffect(() => {
     if (activeLeague && auth.user) {

      setSelectedStat(null);
      setSelectedStatData(null)
      setOdds([])
      setAvailableBookmakers([])
      setGame(null)
      setGameData(null)
      setChartDataList([])
      
      //set activeLeague to localstorage
      localStorage.setItem('activeLeague', activeLeague)

      const fetchDictionary = async () => {
        try {
          const _dictionary = await fetchFromAPI('v3/dictionary', 'POST', { league: activeLeague });
          setDictionary(_dictionary[0])
          setSport(_dictionary[1])
        } catch (error) {
          console.error('Error fetching dictionary:', error)
        }
      }
      fetchDictionary();


    }
  }, [activeLeague, auth.user])
  
  useEffect(() => {
    setGameData(null)
    setChartDataList([])
    setOdds([])
    setAvailableBookmakers([])
    const fetchGameData = async () => {
      let i = myLists.findIndex((s) => s.sport === sport )
      let statArray = myLists[i].contents[list].stats || []
      let g = fetchFromAPI('v3/gameData', 'POST', { 
        id: game.oddsAPIId,
        stats: statArray
      }).then((g) => {
        setGameData(g[0])
        setAvailableBookmakers(g[1])
      }).catch((error) => {
        console.error('Error fetching game data:', error)
      })
    }
    
    if (game && activeLeague) {
      fetchGameData();
    }
  }, [game, list, myLists])


  return (
    <DashboardContext.Provider value={{
      fetchFromAPI, freeRows, leagues, setLeagues,
      activeLeague, setActiveLeague,
      game, setGame,
      list, setList,
      myLists, setMyLists,
      view, setView,
      addStat,
      chartDataList, setChartDataList,
      availableBookmakers, setAvailableBookmakers,
      selectedBookmakers, setSelectedBookmakers,
      betType, setBetType,
      selectedStat, setSelectedStat,
      selectedStatData, setSelectedStatData,
      odds, setOdds,
      gameProps, setGameProps,
      gameData, setGameData,
      getBookmakerLink,
      isDictionaryOpen, setDictionaryOpen,
      dictionary, setDictionary,
      expandedNodes, setexpandedNodes,
      sport, setSport,
      getDictionaryEntry,
    }}>
      {children}
    </DashboardContext.Provider>
  );
};
