import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const CurrentBreakpoint = () => {
  const theme = useTheme();

  // Call useMediaQuery at the top level
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    let currentBreakpoint;

    if (isXl) {
      currentBreakpoint = 'xl';
    } else if (isLg) {
      currentBreakpoint = 'lg';
    } else if (isMd) {
      currentBreakpoint = 'md';
    } else if (isSm) {
      currentBreakpoint = 'sm';
    } else {
      currentBreakpoint = 'xs';
    }

    console.log('Current breakpoint:', currentBreakpoint);
  }, [isXl, isLg, isMd, isSm]); // Dependencies for useEffect

  return <></>;
};

export default CurrentBreakpoint;
