import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  button: {
    backgroundColor: "#f15a29",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d44c20",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(4),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
}));

function PricingSection(props) {
  const classes = useStyles();

  const auth = useAuth();

  const plans = [
   
    {id: "standard",
      name: "Standard Plan",
      description: "Our most popular (and only) plan",
      price: "19.99",
      perks: [
        "FREE 7 DAY TRIAL. Full access to test drive it. Try it. You'll like it.",
        "Daily Statistical Updates",
        "Access to the complete database that includes thousands of stats, an easy-to-navigate data explorer.",
        "Specialty splits: Away/Home, Favorite/Underdog, and Over/Under, and more.",
        "Unlimited number of data points for your game reports",
        "Definitions of every stat and how it's used",
        "Add stats or remove stats from your data collections at any time",
        "Track Most Major North American Bookmakers",
        "Historical Odds Analysis For All Games",
        "Easy To Read Game Reports downloadable in .XLSX and .CSV",
        "1-2 Minute Live Odds & Score Delay",
        "NO HIDDEN FEES, NO ANNOYING ADS, NO SURPRISES. JUST USABLE DATA.",
        "CANCEL AT ANY TIME",
        "REAL CUSTOMER SUPPORT",
      ],
    },
  ];

  
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        mt={1}
      >
        <Container  mt={1}>
          
          <Grid container={true} justifyContent="center" spacing={4}>
            {plans.map((plan, index) => (
              <Grid item={true} xs={12} md={5} key={index}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                  <Typography variant="h5" component="h2">
                  {plan.name}
                    </Typography>
                  <Typography variant="subtitle1" component="h2">
                    </Typography>
              
                  <Button
                    component={Link}
                    to={
                      auth.user
                      ? `/purchase/${plan.id}`
                      : `/auth/signup?next=/purchase/${plan.id}`
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    fullWidth={true}
                    >
                    Start My Free Trial
                    </Button>
                    <Typography variant="h5" >
                      {plan.id === 'free' ? "" : 'then'}
                    </Typography>
                    <Box className={classes.price} mt={1}>
                      <Typography variant="h3">${plan.price}</Typography>
                      <Typography variant="h4" color="textSecondary">
                        /mo
                      </Typography>
                    </Box>

                    {plan.description && (
                      <Box mt={2}>
                        <Typography component="p" color="textSecondary">
                          {plan.description}
                        </Typography>
                      
                        <Box mt="auto" pt={3}>
                    </Box>
                      </Box>
                      
                    )}

                    {plan.perks && (
                      <Box mt={1}>
                        <List aria-label="perks">
                          {plan.perks.map((perk, index) => (
                            <ListItem
                              className={classes.listItem}
                              disableGutters={true}
                              key={index}
                            >
                              <ListItemIcon className={classes.perkIcon}>
                                <CheckIcon />
                              </ListItemIcon>
                              <ListItemText>{perk}</ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    )}

                    
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>
    )
  }

export default PricingSection;
