import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  alertBox: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.shadows[1],
  },
  alertIcon: {
    marginRight: theme.spacing(1),
  },
}));

const CustomAlert = ({ severity, children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.alertBox}>
      <Typography className={classes.alertIcon} variant="body1">
        🚀
      </Typography>
      <Typography variant="body1">{children}</Typography>
    </Box>
  );
};

export default CustomAlert;
