import React, { useState, useEffect, useContext } from 'react';
import { DashboardContext } from './v4Context.js';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  select: {
    borderRadius: 8,
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  selectLabel: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    color: '#333',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  listItemText: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
}));

const BookmakerSelect = () => {
  const classes = useStyles();
  const { setBetType, betType, availableBookmakers, selectedBookmakers, setSelectedBookmakers, getBookmakerLink } = useContext(DashboardContext);
  const betOptions = ['Moneyline', 'Spreads', 'Totals'];
  
  const handleBetChange = (event) => {
    setBetType(event.target.value)
    localStorage.setItem('betType', event.target.value)
  };

  const handleBooksChange = (event) => {
    const { target: { value } } = event;
    setSelectedBookmakers(
      typeof value === 'string' ? value.split(',') : value,
    )
    localStorage.setItem('selectedBookmakers', JSON.stringify(typeof value === 'string' ? value.split(',') : value))
  };

  // Reset selectedBookmakers when availableBookmakers changes
  // useEffect(() => {
  //   setSelectedBookmakers((prevSelectedBookmakers) =>
  //     prevSelectedBookmakers.filter((book) => availableBookmakers.includes(book))
  //   )

  // }, [availableBookmakers]);

  // Sort available bookmakers by their display name in alphabetical order
  const sortedBooksOptions = availableBookmakers
    .filter((book) => book !== 'timestamp')
    .map((book) => {
      const [bookmakerName] = getBookmakerLink(book);
      return { id: book, name: bookmakerName };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      {/* Single Select Dropdown */}
      <FormControl className={classes.formControl}>
        <InputLabel id='bet-select-label' className={classes.selectLabel}>Bet Type</InputLabel>
        <Select
          className={classes.select}
          labelId='bet-select-label'
          id='bet-select'
          value={betType}
          onChange={handleBetChange}
          displayEmpty
          label="Bet Type">
          {betOptions.map((option) => (
            <MenuItem key={option} value={option} className={classes.menuItem}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Multi-Select Dropdown */}
      <FormControl className={classes.formControl}>
        <InputLabel id='book-select-label' className={classes.selectLabel}>Sportsbooks</InputLabel>
        <Select
          className={classes.select}
          labelId='book-select-label'
          id='book-select'
          multiple
          value={selectedBookmakers}
          onChange={handleBooksChange}
          label="Sportsbooks"
          renderValue={(selected) => 
            selected.length === 0 ? 'Sportsbooks' : selected.map((id) => getBookmakerLink(id)[0]).join(', ')
          }
        >
          {sortedBooksOptions.map(({ id, name }) => (
            <MenuItem key={id} value={id} className={classes.menuItem}>
              <Checkbox className={classes.checkbox} checked={selectedBookmakers.indexOf(id) > -1} />
              <ListItemText className={classes.listItemText} primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default BookmakerSelect;
