import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "What is StatFactory for?",
      answer:
      "StatFactory is a sports data platform that provides a wide range of stats for NFL, NBA, and MLB. Our goal is to provide sports betting and fantasy enthusiasts with the reliable and repeatable data. We hope to save our users time in data collection and improve analysis. Whether you're a casual fan or a seasoned pro, StatFactory has something for you.",
    },
    {
      question: "How much does it cost?",
      answer:
        "Limited use of StatFactory is free. For more advanced features, we offer a paid subscription plan for $19/mo. with a 7 day trial. This includes unlimited datapoints, unlimited stat collections, and unlimited exports. Please see our pricing page for more details.",
    },
    {
      question: "What stats do you provide?",
      answer:
        "We provide a wide range of stats for NFL, NBA, and MLB. This includes player stats, team stats, game stats, and more. Though stats are different for each sport, generally, we provide win-loss records, sportsbook odds and stats, team and player performance stats and scoring metrics in full season and short term time frames. We prepare splits for Home v. Visitor, Favorite v. Underdog, and inter-conference performances.", 
    },
    {
      question: "How frequently are stats updated?",
      answer:
        "Daily stats are updated around 4:30am EST. Odds are updated at 5 minute intervals prior to gametime, and 1 minute intervals during the game. We also provide historical data for all games, which is updated within 24 hours of the game's completion. We are constantly working to improve our data quality and update frequency. If you have any specific requests, please let us know!",
    },
    {
      question: "Can I download my data?",
      answer:
        "Yes, data export is provided for all users. You can export daily data collections in CSV (Text), XLS (Excel), and JSON formats.",
    },
    {
      question: "Do you have plans to add more leagues?",
      answer:
        "Yes, Men's NCAA Football and Basketball are next on our list. We are also considering adding NHL, MLS, and WNBA. If you have a specific league you would like to see, please let us know! We are always looking for ways to improve our product.",
    },
    {
      question: "What kind of user data do you collect?",
      answer:
        "At this time, we only collect user data for the purposes of account creation, billing, and functional use of the StatFactory App. We do not collect any personal data beyond what is necessary for these purposes. We do not sell or share user data with third parties. For more information, please see our Privacy Policy.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Typography variant="h6">
          Need Help? Email me at <a href="mailto:matt@statfactory.io">matt@statfactory.io</a>
        </Typography>
        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
