import React, { useState, useEffect, useContext } from 'react';
import { DashboardContext } from './v4Context.js';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Checkbox, FormControlLabel, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: '68%',
    [theme.breakpoints.up('xl')]: {
      maxHeight: 600,
      minWidth: 500,
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: 300,
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  chartWrapper: {
    height: 250,
    width: '100%',
  },
  toggleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: theme.spacing(0),
  },
  emptyChartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 350,
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: theme.spacing(1),
  },
  emptyChartText: {
    fontSize: '1rem',
    color: '#666',
  },
}));

const OddsChart = () => {
  const classes = useStyles();
  const { fetchFromAPI, game, activeLeague, selectedBookmakers, betType } = useContext(DashboardContext);
  const [oddsData, setOddsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lineVisibility, setLineVisibility] = useState({});


  useEffect(() => {
    if (!game) return;
    const fetchOddsData = async () => {
      const endpoint = 'v3/oddsData';
      const method = 'POST';
      const data = {
        league: activeLeague,
        gameId: game.oddsAPIId,
        mkt: betType,
        bookmakers: selectedBookmakers,
      };

      try {
        setLoading(true);
        const response = await fetchFromAPI(endpoint, method, data);

        // Transform the data
        const transformDataForChart = (data) => {
          return data.map((entry) => {
            const transformedEntry = { time: entry.timestamp };
            Object.entries(entry).forEach(([key, value]) => {
              if (key !== 'timestamp') {
                if (key.includes('overround')) return; // Skip overround data
                const keyParts = key.split('_');
                if (keyParts.length >= 4) {
                  const metric = keyParts.pop();
                  const team = keyParts.slice(2).join('_');
                  const newKey = `${team}_${metric}`;
                  transformedEntry[newKey] = value;
                } else {
                  console.warn('Unexpected key format:', key);
                }
              }
            });
            return transformedEntry;
          });
        };

        const chartData = transformDataForChart(response);
        setOddsData(chartData);

        const visibility = Object.keys(chartData[0] || {}).reduce((acc, key) => {
          if (key !== 'time') acc[key] = true;
          return acc;
        }, {});
        setLineVisibility(visibility);
      } catch (error) {
        console.error('Error fetching odds data:', error);
        setOddsData([]);
      } finally {
        setLoading(false);
      }
    };
    fetchOddsData();
  }, [game, activeLeague, selectedBookmakers, betType]);

  const handleToggleLine = (lineKey) => {
    setLineVisibility((prev) => ({ ...prev, [lineKey]: !prev[lineKey] }));
  };

  const formatLabel = (key) => {
    return key
      .split('_')
      .map((word) =>
        word === 'value'
          ? 'Odds'
          : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(' ');
  };

  if (!game) {
    return null;
  }

  if (loading) {
    return (
      <Paper elevation={2} className={classes.componentContainer}>
        <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
          Odds History
        </Typography>
        <div className={classes.emptyChartContainer}>
      <LinearProgress />
        </div>
      </Paper>
    );
  }
  let visitornameflat = game.visitor.teamName.replace(/\s/g, '').toLowerCase()
  let homenameflat = game.home.teamName.replace(/\s/g, '').toLowerCase()
  console.log(homenameflat, visitornameflat)
  return (
    <Paper elevation={2} className={classes.componentContainer}>
      <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
        Odds History
      </Typography>
      {selectedBookmakers.length === 0 ? (
        <div className={classes.emptyChartContainer}>
          <Typography className={classes.emptyChartText}>
            Select a SportsBook from the Line Shop to Chart Odds
          </Typography>
        </div>
      ) : (
        <>
          <div className={classes.toggleContainer}>
            {Object.keys(lineVisibility).map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={lineVisibility[key]}
                    onChange={() => handleToggleLine(key)}
                  />
                }
                label={formatLabel(key)}
              />
            ))}
          </div>
          <ResponsiveContainer width="100%" height={350}>
            <LineChart data={oddsData}>
              <XAxis
                dataKey="time"
                tickFormatter={(time) =>
                  new Date(time).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                }
              />
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis />
              <Tooltip
                labelFormatter={(time) =>
                  new Date(time).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                }
                formatter={(value, name) => [value, formatLabel(name)]}
              />
              <Brush dataKey="time" height={20} stroke="#8884d8" />
              {Object.keys(lineVisibility)
                .filter((key) => lineVisibility[key])
                .map((key) => {
                  let keyRef = key.split('_')
                  let vName = keyRef[0]
                  let market = keyRef[1].toLowerCase()
                  vName = vName.replace(/\s/g, '').toLowerCase()
                  let primaryColor = vName === visitornameflat ? game.visitor.primaryColor : 
                  vName === homenameflat ? game.home.primaryColor : 
                  vName === 'over' ? "rgba(139, 0, 0, 1)" : "rgba(0, 100, 0, 1)" 

                  let secondaryColor = vName === visitornameflat ? game.visitor.secondaryColor :
                  vName === homenameflat ? game.home.secondaryColor :
                  vName === 'over' ? "rgba(139, 0, 0, 0.5)" : "rgba(0, 100, 0, 0.5)"

                  market === 'point' ? primaryColor = secondaryColor : primaryColor = primaryColor

                  return (
                    <Line
                    key={key}
                    type="monotone"
                    dataKey={key}
                    stroke={primaryColor}
                    strokeWidth={2}
                    name={formatLabel(key)}
                    dot={false}
                    />
                  )
                })
              }
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </Paper>
  );
};

export default React.memo(OddsChart);
